import ApiService from "./api.service";

const SettingsService = {
  async getSettings() {
    return await ApiService.commonGETRequest("/settings/");
  },
  async editSettings(settings) {
    return await ApiService.commonPUTRequest("/settings/", {}, settings);
  },
  async getPatientCardSettings(cardType) {
    return await ApiService.commonGETRequest(
      "/patientcardsettings/",
      cardType ? { type: cardType } : undefined
    );
  },
  async addFieldToPatientCardSettings(data, cardType) {
    return await ApiService.commonPOSTRequest(
      "/patientcardsettings/",
      cardType ? { type: cardType } : undefined,
      data
    );
  },
  async removeFieldToPatientCardSettings(data, cardType) {
    return await ApiService.commonDELETERequest(
      "/patientcardsettings/",
      {
        ...data,
        type: cardType ? cardType : undefined
      }
    );
  },
  async updatePatientCardSettings(data) {
    return await ApiService.commonPUTRequest("/patientcardsettings/", {}, data);
  },
};

export default SettingsService;
