const FIELD_TYPES = {
    TEXT_FIELD: 0,
    DRAWINGS_FIELD: 1,
    ICD9_CODES_FIELD: 2,
    ICD10_CODES_FIELD: 3,
    ICF_CODES_FIELD: 4,
    DATE_FIELD: 5,
    DRA_TABLE_FIELD: 6,
    PER_OXF_SCALE_FIELD: 7,
    POP_Q_FIELD: 8,
    DRAWINGS_DRA_FIELD: 9,
    DRAWINGS_SCARS_FIELD: 10,
    DRAWINGS_VIS_CROTCH_FIELD: 11,
    DRAWINGS_CROTCH_FIELD: 12,
    PREGNANCY_STATE_FIELD: 13,
    PREGNANCY_LIST_FIELD: 14,
    PREGNANCY_INTERVIEW_FIELD: 15,
    SWITCH_FIELD: 16,
    CHILD_OVERALL_ASSESSMENT_FIELD: 17,
    DRAWINGS_CHILD_FIELD: 18,
    DRAWINGS_INFANT_BACK_FIELD: 19,
    DRAWINGS_INFANT_FRONT_FIELD: 20,
    CHILD_ACTIVITY_ASSESSMENT_FIELD: 21
};

const DRAWING_FIELDS = [
    FIELD_TYPES.DRAWINGS_FIELD,
    FIELD_TYPES.DRAWINGS_DRA_FIELD,
    FIELD_TYPES.DRAWINGS_SCARS_FIELD,
    FIELD_TYPES.DRAWINGS_VIS_CROTCH_FIELD,
    FIELD_TYPES.DRAWINGS_CROTCH_FIELD,
    FIELD_TYPES.DRAWINGS_CHILD_FIELD,
    FIELD_TYPES.DRAWINGS_INFANT_BACK_FIELD,
    FIELD_TYPES.DRAWINGS_INFANT_FRONT_FIELD
];

const SINGLE_DRAWING_FIELDS = [
    FIELD_TYPES.DRAWINGS_DRA_FIELD,
    FIELD_TYPES.DRAWINGS_SCARS_FIELD,
    FIELD_TYPES.DRAWINGS_VIS_CROTCH_FIELD,
    FIELD_TYPES.DRAWINGS_CROTCH_FIELD,
    FIELD_TYPES.DRAWINGS_CHILD_FIELD,
    FIELD_TYPES.DRAWINGS_INFANT_BACK_FIELD,
    FIELD_TYPES.DRAWINGS_INFANT_FRONT_FIELD
]

const MEDICAL_CODES_FIELDS = [
    FIELD_TYPES.ICF_CODES_FIELD,
    FIELD_TYPES.ICD9_CODES_FIELD,
    FIELD_TYPES.ICD10_CODES_FIELD
]

export {
    FIELD_TYPES, 
    DRAWING_FIELDS, 
    SINGLE_DRAWING_FIELDS, 
    MEDICAL_CODES_FIELDS
};